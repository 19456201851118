"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.INITIAL_STATE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _variables = require("../variables");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INITIAL_STATE = exports.INITIAL_STATE = {
  errorListValets: false,
  loadingListValets: false,
  errorListPastValets: false,
  loadingListPastValets: false,
  errorEditValet: false,
  loadingEditValet: false,
  errorGetValet: false,
  loadingGetValet: false,
  valetsList: [],
  pastValetsList: [],
  valetById: {},
  listCount: 0,
  listPages: 0,
  pastListCount: 0,
  pastListPages: 0,
  loadingValetUsageByPeriods: false,
  loadingValetMovementByDay: false,
  valetUsageByPeriods: {},
  valetMovementByDay: [],
  errorValetUsageByPeriods: false,
  errorValetMovementByDay: false,
  valetCustomersReport: [],
  loadingValetCustomersReport: false,
  errorValetCustomersReport: false,
  reportListCount: 0,
  reportListPages: 0,
  listPaymentMethodFilters: [],
  errorPaymentMethodFilters: false,
  loadingPaymentMethodFilters: false,
  listSortByFilters: [],
  errorSortByFilters: false,
  loadingSortByFilters: false,
  listValetAbility: [],
  errorValetAbility: false,
  loadingValetAbility: false,
  listCurrentValetFee: [],
  errorCurrentValetFee: false,
  loadingCurrentValetFee: false,
  listValetAllowCheckout: [],
  errorValetAllowCheckout: false,
  loadingValetAllowCheckout: false,
  listValetPaymentSummary: [],
  errorValetPaymentSummary: false,
  loadingValetPaymentSummary: false,
  listExportCustomersReportPDF: [],
  errorExportCustomersReportPDF: false,
  loadingExportCustomersReportPDF: false,
  listExportCustomersReportCSV: [],
  errorExportCustomersReportCSV: false,
  loadingExportCustomersReportCSV: false,
  listChangeValetFee: [],
  errorChangeValetFee: false,
  loadingChangeValetFee: false,
  listCurrentPriceTableFee: [],
  errorCurrentPriceTableFee: false,
  loadingCurrentPriceTableFee: false
};
var _default = exports["default"] = function _default() {
  var _action$payload$data, _action$payload, _action$payload$data2, _action$payload2, _action$payload$data3, _action$payload3, _action$payload$data4, _action$payload4, _action$payload$data5, _action$payload5, _action$payload$data6, _action$payload6, _action$payload$data7, _action$payload7, _action$payload$data8, _action$payload8, _action$payload$data9, _action$payload9, _action$payload$data10, _action$payload10, _action$payload$data11, _action$payload11, _action$payload$data12, _action$payload12, _action$payload$data13, _action$payload13, _action$payload$data14, _action$payload14, _action$payload$data15, _action$payload15, _action$payload$data16, _action$payload16, _action$payload$data17, _action$payload17, _action$payload$data18, _action$payload18, _action$payload$data19, _action$payload19, _action$payload$data20, _action$payload20, _action$payload$data21, _action$payload21, _action$payload$data22, _action$payload22, _action$payload$data23, _action$payload23, _action$payload$data24, _action$payload24, _action$payload$data25, _action$payload25, _action$payload$data26, _action$payload26, _action$payload$data27, _action$payload27, _action$payload$data28, _action$payload28, _action$payload$data29, _action$payload29, _action$payload$data30, _action$payload30, _action$payload$data31, _action$payload31, _action$payload$data32, _action$payload32, _action$payload$data33, _action$payload33;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _variables.LIST_PAST_VALETS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorListPastValets: null,
        loadingListPastValets: true
      });
    case _variables.LIST_PAST_VALETS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListPastValets: false,
        errorListPastValets: null,
        pastValetsList: (_action$payload$data = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data) !== null && _action$payload$data !== void 0 ? _action$payload$data : [],
        pastListCount: action.payload.headers['total-count'],
        pastListPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_PAST_VALETS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListPastValets: false,
        errorListPastValets: (_action$payload$data2 = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data) !== null && _action$payload$data2 !== void 0 ? _action$payload$data2 : {}
      });
    case _variables.VALET_ABILITY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAbility: true
      });
    case _variables.VALET_ABILITY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAbility: false,
        errorValetAbility: false,
        listValetAbility: (_action$payload$data3 = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data) !== null && _action$payload$data3 !== void 0 ? _action$payload$data3 : []
      });
    case _variables.VALET_ABILITY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAbility: false,
        errorValetAbility: (_action$payload$data4 = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data) !== null && _action$payload$data4 !== void 0 ? _action$payload$data4 : {}
      });
    case _variables.CURRENT_VALET_FEE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentValetFee: true
      });
    case _variables.CURRENT_VALET_FEE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentValetFee: false,
        errorCurrentValetFee: false,
        listCurrentValetFee: (_action$payload$data5 = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data) !== null && _action$payload$data5 !== void 0 ? _action$payload$data5 : []
      });
    case _variables.CURRENT_VALET_FEE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentValetFee: false,
        errorCurrentValetFee: (_action$payload$data6 = action === null || action === void 0 || (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data) !== null && _action$payload$data6 !== void 0 ? _action$payload$data6 : {}
      });
    case _variables.VALET_PAYMENT_SUMMARY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetPaymentSummary: true
      });
    case _variables.VALET_PAYMENT_SUMMARY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetPaymentSummary: false,
        errorValetPaymentSummary: false,
        listValetPaymentSummary: (_action$payload$data7 = action === null || action === void 0 || (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.data) !== null && _action$payload$data7 !== void 0 ? _action$payload$data7 : []
      });
    case _variables.VALET_PAYMENT_SUMMARY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetPaymentSummary: false,
        errorValetPaymentSummary: (_action$payload$data8 = action === null || action === void 0 || (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.data) !== null && _action$payload$data8 !== void 0 ? _action$payload$data8 : {}
      });
    case _variables.VALET_ALLOW_CHECKOUT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAllowCheckout: true
      });
    case _variables.VALET_ALLOW_CHECKOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAllowCheckout: false,
        errorValetAllowCheckout: false,
        listValetAllowCheckout: (_action$payload$data9 = action === null || action === void 0 || (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.data) !== null && _action$payload$data9 !== void 0 ? _action$payload$data9 : []
      });
    case _variables.VALET_ALLOW_CHECKOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetAllowCheckout: false,
        errorValetAllowCheckout: (_action$payload$data10 = action === null || action === void 0 || (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data) !== null && _action$payload$data10 !== void 0 ? _action$payload$data10 : {}
      });
    case _variables.CHANGE_VALET_FEE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeValetFee: true
      });
    case _variables.CHANGE_VALET_FEE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeValetFee: false,
        errorChangeValetFee: false,
        listChangeValetFee: (_action$payload$data11 = action === null || action === void 0 || (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.data) !== null && _action$payload$data11 !== void 0 ? _action$payload$data11 : []
      });
    case _variables.CHANGE_VALET_FEE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingChangeValetFee: false,
        errorChangeValetFee: (_action$payload$data12 = action === null || action === void 0 || (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.data) !== null && _action$payload$data12 !== void 0 ? _action$payload$data12 : {}
      });
    case _variables.CURRENT_PRICE_TABLE_FEE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentPriceTableFee: true
      });
    case _variables.CURRENT_PRICE_TABLE_FEE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentPriceTableFee: false,
        errorCurrentPriceTableFee: false,
        listCurrentPriceTableFee: (_action$payload$data13 = action === null || action === void 0 || (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.data) !== null && _action$payload$data13 !== void 0 ? _action$payload$data13 : []
      });
    case _variables.CURRENT_PRICE_TABLE_FEE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCurrentPriceTableFee: false,
        errorCurrentPriceTableFee: (_action$payload$data14 = action === null || action === void 0 || (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data) !== null && _action$payload$data14 !== void 0 ? _action$payload$data14 : {}
      });
    case _variables.VALET_CUSTOMERS_REPORT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorValetCustomersReport: null,
        loadingValetCustomersReport: true
      });
    case _variables.VALET_CUSTOMERS_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetCustomersReport: false,
        errorValetCustomersReport: null,
        valetCustomersReport: (_action$payload$data15 = action === null || action === void 0 || (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.data) !== null && _action$payload$data15 !== void 0 ? _action$payload$data15 : [],
        reportListCount: action.payload.headers['total-count'],
        reportListPages: action.payload.headers['total-pages']
      });
    case _variables.VALET_CUSTOMERS_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetCustomersReport: false,
        errorValetCustomersReport: (_action$payload$data16 = action === null || action === void 0 || (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.data) !== null && _action$payload$data16 !== void 0 ? _action$payload$data16 : {}
      });
    case _variables.LIST_PAYMENT_METHOD_FILTERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPaymentMethodFilters: true
      });
    case _variables.LIST_PAYMENT_METHOD_FILTERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPaymentMethodFilters: false,
        errorPaymentMethodFilters: false,
        listPaymentMethodFilters: (_action$payload$data17 = action === null || action === void 0 || (_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : _action$payload17.data) !== null && _action$payload$data17 !== void 0 ? _action$payload$data17 : []
      });
    case _variables.LIST_PAYMENT_METHOD_FILTERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPaymentMethodFilters: false,
        errorPaymentMethodFilters: (_action$payload$data18 = action === null || action === void 0 || (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : _action$payload18.data) !== null && _action$payload$data18 !== void 0 ? _action$payload$data18 : {}
      });
    case _variables.LIST_SORT_BY_FILTERS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSortByFilters: true
      });
    case _variables.LIST_SORT_BY_FILTERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSortByFilters: false,
        errorSortByFilters: false,
        listSortByFilters: (_action$payload$data19 = action === null || action === void 0 || (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : _action$payload19.data) !== null && _action$payload$data19 !== void 0 ? _action$payload$data19 : []
      });
    case _variables.LIST_SORT_BY_FILTERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSortByFilters: false,
        errorSortByFilters: (_action$payload$data20 = action === null || action === void 0 || (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.data) !== null && _action$payload$data20 !== void 0 ? _action$payload$data20 : {}
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_PDF_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportPDF: true
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_PDF_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportPDF: false,
        errorExportCustomersReportPDF: false,
        listExportCustomersReportPDF: (_action$payload$data21 = action === null || action === void 0 || (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : _action$payload21.data) !== null && _action$payload$data21 !== void 0 ? _action$payload$data21 : []
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_PDF_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportPDF: false,
        errorExportCustomersReportPDF: (_action$payload$data22 = action === null || action === void 0 || (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : _action$payload22.data) !== null && _action$payload$data22 !== void 0 ? _action$payload$data22 : {}
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_CSV_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportCSV: true
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_CSV_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportCSV: false,
        errorExportCustomersReportCSV: false,
        listExportCustomersReportCSV: (_action$payload$data23 = action === null || action === void 0 || (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : _action$payload23.data) !== null && _action$payload$data23 !== void 0 ? _action$payload$data23 : []
      });
    case _variables.EXPORT_CUSTOMERS_REPORT_CSV_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingExportCustomersReportCSV: false,
        errorExportCustomersReportCSV: (_action$payload$data24 = action === null || action === void 0 || (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : _action$payload24.data) !== null && _action$payload$data24 !== void 0 ? _action$payload$data24 : {}
      });
    case _variables.LIST_VALETS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorListValets: null,
        loadingListValets: true
      });
    case _variables.LIST_VALETS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListValets: false,
        errorListValets: null,
        valetsList: (_action$payload$data25 = action === null || action === void 0 || (_action$payload25 = action.payload) === null || _action$payload25 === void 0 ? void 0 : _action$payload25.data) !== null && _action$payload$data25 !== void 0 ? _action$payload$data25 : [],
        listCount: action.payload.headers['total-count'],
        listPages: action.payload.headers['total-pages']
      });
    case _variables.LIST_VALETS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingListValets: false,
        errorListValets: (_action$payload$data26 = action === null || action === void 0 || (_action$payload26 = action.payload) === null || _action$payload26 === void 0 ? void 0 : _action$payload26.data) !== null && _action$payload$data26 !== void 0 ? _action$payload$data26 : {}
      });
    case _variables.GET_VALET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorGetValet: null,
        loadingGetValet: true
      });
    case _variables.GET_VALET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetValet: false,
        errorGetValet: null,
        valetById: (_action$payload$data27 = action === null || action === void 0 || (_action$payload27 = action.payload) === null || _action$payload27 === void 0 ? void 0 : _action$payload27.data) !== null && _action$payload$data27 !== void 0 ? _action$payload$data27 : {}
      });
    case _variables.GET_VALET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGetValet: false,
        errorGetValet: (_action$payload$data28 = action === null || action === void 0 || (_action$payload28 = action.payload) === null || _action$payload28 === void 0 ? void 0 : _action$payload28.data) !== null && _action$payload$data28 !== void 0 ? _action$payload$data28 : {}
      });
    case _variables.EDIT_VALET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorEditValet: null,
        loadingEditValet: true
      });
    case _variables.EDIT_VALET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditValet: false,
        errorEditValet: null
      });
    case _variables.EDIT_VALET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingEditValet: false,
        errorEditValet: (_action$payload$data29 = action === null || action === void 0 || (_action$payload29 = action.payload) === null || _action$payload29 === void 0 ? void 0 : _action$payload29.data) !== null && _action$payload$data29 !== void 0 ? _action$payload$data29 : {}
      });
    case _variables.VALET_MOVEMENT_BY_DAY_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetMovementByDay: true,
        errorValetMovementByDay: false
      });
    case _variables.VALET_MOVEMENT_BY_DAY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetMovementByDay: false,
        valetMovementByDay: (_action$payload$data30 = action === null || action === void 0 || (_action$payload30 = action.payload) === null || _action$payload30 === void 0 ? void 0 : _action$payload30.data) !== null && _action$payload$data30 !== void 0 ? _action$payload$data30 : []
      });
    case _variables.VALET_MOVEMENT_BY_DAY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetMovementByDay: false,
        errorValetMovementByDay: (_action$payload$data31 = action === null || action === void 0 || (_action$payload31 = action.payload) === null || _action$payload31 === void 0 ? void 0 : _action$payload31.data) !== null && _action$payload$data31 !== void 0 ? _action$payload$data31 : {}
      });
    case _variables.VALET_USAGE_BY_PERIODS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetUsageByPeriods: true,
        errorValetUsageByPeriods: false
      });
    case _variables.VALET_USAGE_BY_PERIODS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetUsageByPeriods: false,
        valetUsageByPeriods: (_action$payload$data32 = action === null || action === void 0 || (_action$payload32 = action.payload) === null || _action$payload32 === void 0 ? void 0 : _action$payload32.data) !== null && _action$payload$data32 !== void 0 ? _action$payload$data32 : {}
      });
    case _variables.VALET_USAGE_BY_PERIODS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingValetUsageByPeriods: false,
        errorValetUsageByPeriods: (_action$payload$data33 = action === null || action === void 0 || (_action$payload33 = action.payload) === null || _action$payload33 === void 0 ? void 0 : _action$payload33.data) !== null && _action$payload$data33 !== void 0 ? _action$payload$data33 : {}
      });
    default:
      return state;
  }
};