"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_VEHICLE_TYPE_SUCCESS = exports.LIST_VEHICLE_TYPE_REQUEST = exports.LIST_VEHICLE_TYPE_FAIL = exports.LIST_VEHICLES_SUCCESS = exports.LIST_VEHICLES_REQUEST = exports.LIST_VEHICLES_FAIL = exports.EDIT_VEHICLE_TYPE_SUCCESS = exports.EDIT_VEHICLE_TYPE_REQUEST = exports.EDIT_VEHICLE_TYPE_FAIL = exports.EDIT_VEHICLE_SUCCESS = exports.EDIT_VEHICLE_REQUEST = exports.EDIT_VEHICLE_FAIL = exports.DELETE_VEHICLE_TYPE_SUCCESS = exports.DELETE_VEHICLE_TYPE_REQUEST = exports.DELETE_VEHICLE_TYPE_FAIL = exports.DELETE_VEHICLE_SUCCESS = exports.DELETE_VEHICLE_REQUEST = exports.DELETE_VEHICLE_FAIL = exports.CREATE_VEHICLE_TYPE_SUCCESS = exports.CREATE_VEHICLE_TYPE_REQUEST = exports.CREATE_VEHICLE_TYPE_FAIL = exports.CREATE_VEHICLE_SUCCESS = exports.CREATE_VEHICLE_REQUEST = exports.CREATE_VEHICLE_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var LIST_VEHICLES_REQUEST = exports.LIST_VEHICLES_REQUEST = '@Vehicle/LIST_VEHICLES_REQUEST';
var LIST_VEHICLES_SUCCESS = exports.LIST_VEHICLES_SUCCESS = '@Vehicle/LIST_VEHICLES_SUCCESS';
var LIST_VEHICLES_FAIL = exports.LIST_VEHICLES_FAIL = '@Vehicle/LIST_VEHICLES_FAIL';
var CREATE_VEHICLE_REQUEST = exports.CREATE_VEHICLE_REQUEST = '@Vehicle/CREATE_VEHICLE_REQUEST';
var CREATE_VEHICLE_SUCCESS = exports.CREATE_VEHICLE_SUCCESS = '@Vehicle/CREATE_VEHICLE_SUCCESS';
var CREATE_VEHICLE_FAIL = exports.CREATE_VEHICLE_FAIL = '@Vehicle/CREATE_VEHICLE_FAIL';
var CREATE_VEHICLE_TYPE_REQUEST = exports.CREATE_VEHICLE_TYPE_REQUEST = '@Vehicle/CREATE_VEHICLE_TYPE_REQUEST';
var CREATE_VEHICLE_TYPE_SUCCESS = exports.CREATE_VEHICLE_TYPE_SUCCESS = '@Vehicle/CREATE_VEHICLE_TYPE_SUCCESS';
var CREATE_VEHICLE_TYPE_FAIL = exports.CREATE_VEHICLE_TYPE_FAIL = '@Vehicle/CREATE_VEHICLE_TYPE_FAIL';
var LIST_VEHICLE_TYPE_REQUEST = exports.LIST_VEHICLE_TYPE_REQUEST = '@Vehicle/LIST_VEHICLE_TYPE_REQUEST';
var LIST_VEHICLE_TYPE_SUCCESS = exports.LIST_VEHICLE_TYPE_SUCCESS = '@Vehicle/LIST_VEHICLE_TYPE_SUCCESS';
var LIST_VEHICLE_TYPE_FAIL = exports.LIST_VEHICLE_TYPE_FAIL = '@Vehicle/LIST_VEHICLE_TYPE_FAIL';
var DELETE_VEHICLE_REQUEST = exports.DELETE_VEHICLE_REQUEST = '@Vehicle/DELETE_VEHICLE_REQUEST';
var DELETE_VEHICLE_SUCCESS = exports.DELETE_VEHICLE_SUCCESS = '@Vehicle/DELETE_VEHICLE_SUCCESS';
var DELETE_VEHICLE_FAIL = exports.DELETE_VEHICLE_FAIL = '@Vehicle/DELETE_VEHICLE_FAIL';
var DELETE_VEHICLE_TYPE_REQUEST = exports.DELETE_VEHICLE_TYPE_REQUEST = '@Vehicle/DELETE_VEHICLE_TYPE_REQUEST';
var DELETE_VEHICLE_TYPE_SUCCESS = exports.DELETE_VEHICLE_TYPE_SUCCESS = '@Vehicle/DELETE_VEHICLE_TYPE_SUCCESS';
var DELETE_VEHICLE_TYPE_FAIL = exports.DELETE_VEHICLE_TYPE_FAIL = '@Vehicle/DELETE_VEHICLE_TYPE_FAIL';
var EDIT_VEHICLE_TYPE_REQUEST = exports.EDIT_VEHICLE_TYPE_REQUEST = '@Vehicle/EDIT_VEHICLE_TYPE_REQUEST';
var EDIT_VEHICLE_TYPE_SUCCESS = exports.EDIT_VEHICLE_TYPE_SUCCESS = '@Vehicle/EDIT_VEHICLE_TYPE_SUCCESS';
var EDIT_VEHICLE_TYPE_FAIL = exports.EDIT_VEHICLE_TYPE_FAIL = '@Vehicle/EDIT_VEHICLE_TYPE_FAIL';
var EDIT_VEHICLE_REQUEST = exports.EDIT_VEHICLE_REQUEST = '@Vehicle/EDIT_VEHICLE_REQUEST';
var EDIT_VEHICLE_SUCCESS = exports.EDIT_VEHICLE_SUCCESS = '@Vehicle/EDIT_VEHICLE_SUCCESS';
var EDIT_VEHICLE_FAIL = exports.EDIT_VEHICLE_FAIL = '@Vehicle/EDIT_VEHICLE_FAIL';