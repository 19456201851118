"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALET_USAGE_BY_PERIODS_SUCCESS = exports.VALET_USAGE_BY_PERIODS_REQUEST = exports.VALET_USAGE_BY_PERIODS_FAIL = exports.VALET_PAYMENT_SUMMARY_SUCCESS = exports.VALET_PAYMENT_SUMMARY_REQUEST = exports.VALET_PAYMENT_SUMMARY_FAIL = exports.VALET_MOVEMENT_BY_DAY_SUCCESS = exports.VALET_MOVEMENT_BY_DAY_REQUEST = exports.VALET_MOVEMENT_BY_DAY_FAIL = exports.VALET_CUSTOMERS_REPORT_SUCCESS = exports.VALET_CUSTOMERS_REPORT_REQUEST = exports.VALET_CUSTOMERS_REPORT_FAIL = exports.VALET_ALLOW_CHECKOUT_SUCCESS = exports.VALET_ALLOW_CHECKOUT_REQUEST = exports.VALET_ALLOW_CHECKOUT_FAIL = exports.VALET_ABILITY_SUCCESS = exports.VALET_ABILITY_REQUEST = exports.VALET_ABILITY_FAIL = exports.LIST_VALETS_SUCCESS = exports.LIST_VALETS_REQUEST = exports.LIST_VALETS_FAIL = exports.LIST_SORT_BY_FILTERS_SUCCESS = exports.LIST_SORT_BY_FILTERS_REQUEST = exports.LIST_SORT_BY_FILTERS_FAIL = exports.LIST_PAYMENT_METHOD_FILTERS_SUCCESS = exports.LIST_PAYMENT_METHOD_FILTERS_REQUEST = exports.LIST_PAYMENT_METHOD_FILTERS_FAIL = exports.LIST_PAST_VALETS_SUCCESS = exports.LIST_PAST_VALETS_REQUEST = exports.LIST_PAST_VALETS_FAIL = exports.GET_VALET_SUCCESS = exports.GET_VALET_REQUEST = exports.GET_VALET_FAIL = exports.EXPORT_CUSTOMERS_REPORT_PDF_SUCCESS = exports.EXPORT_CUSTOMERS_REPORT_PDF_REQUEST = exports.EXPORT_CUSTOMERS_REPORT_PDF_FAIL = exports.EXPORT_CUSTOMERS_REPORT_CSV_SUCCESS = exports.EXPORT_CUSTOMERS_REPORT_CSV_REQUEST = exports.EXPORT_CUSTOMERS_REPORT_CSV_FAIL = exports.EDIT_VALET_SUCCESS = exports.EDIT_VALET_REQUEST = exports.EDIT_VALET_FAIL = exports.CURRENT_VALET_FEE_SUCCESS = exports.CURRENT_VALET_FEE_REQUEST = exports.CURRENT_VALET_FEE_FAIL = exports.CURRENT_PRICE_TABLE_FEE_SUCCESS = exports.CURRENT_PRICE_TABLE_FEE_REQUEST = exports.CURRENT_PRICE_TABLE_FEE_FAIL = exports.CHANGE_VALET_FEE_SUCCESS = exports.CHANGE_VALET_FEE_REQUEST = exports.CHANGE_VALET_FEE_FAIL = void 0;
// USE THIS FILE TO CREATE AND EXPORT YOUR MODULE VARIABLES
var LIST_VALETS_REQUEST = exports.LIST_VALETS_REQUEST = '@Valet/LIST_VALETS_REQUEST';
var LIST_VALETS_SUCCESS = exports.LIST_VALETS_SUCCESS = '@Valet/LIST_VALETS_SUCCESS';
var LIST_VALETS_FAIL = exports.LIST_VALETS_FAIL = '@Valet/LIST_VALETS_FAIL';
var LIST_PAST_VALETS_REQUEST = exports.LIST_PAST_VALETS_REQUEST = '@Valet/LIST_PAST_VALETS_REQUEST';
var LIST_PAST_VALETS_SUCCESS = exports.LIST_PAST_VALETS_SUCCESS = '@Valet/LIST_PAST_VALETS_SUCCESS';
var LIST_PAST_VALETS_FAIL = exports.LIST_PAST_VALETS_FAIL = '@Valet/LIST_PAST_VALETS_FAIL';
var EDIT_VALET_REQUEST = exports.EDIT_VALET_REQUEST = '@Valet/EDIT_VALET_REQUEST';
var EDIT_VALET_SUCCESS = exports.EDIT_VALET_SUCCESS = '@Valet/EDIT_VALET_SUCCESS';
var EDIT_VALET_FAIL = exports.EDIT_VALET_FAIL = '@Valet/EDIT_VALET_FAIL';
var GET_VALET_REQUEST = exports.GET_VALET_REQUEST = '@Valet /GET_VALET_REQUEST';
var GET_VALET_SUCCESS = exports.GET_VALET_SUCCESS = '@Valet/GET_VALET_SUCCESS';
var GET_VALET_FAIL = exports.GET_VALET_FAIL = '@Valet/GET_VALET_FAIL';
var VALET_MOVEMENT_BY_DAY_REQUEST = exports.VALET_MOVEMENT_BY_DAY_REQUEST = '@Valet/VALET_MOVEMENT_BY_DAY_REQUEST';
var VALET_MOVEMENT_BY_DAY_SUCCESS = exports.VALET_MOVEMENT_BY_DAY_SUCCESS = '@Valet/VALET_MOVEMENT_BY_DAY_SUCCESS';
var VALET_MOVEMENT_BY_DAY_FAIL = exports.VALET_MOVEMENT_BY_DAY_FAIL = '@Valet/VALET_MOVEMENT_BY_DAY_FAIL';
var VALET_USAGE_BY_PERIODS_REQUEST = exports.VALET_USAGE_BY_PERIODS_REQUEST = '@Valet/VALET_USAGE_BY_PERIODS_REQUEST';
var VALET_USAGE_BY_PERIODS_SUCCESS = exports.VALET_USAGE_BY_PERIODS_SUCCESS = '@Valet/VALET_USAGE_BY_PERIODS_SUCCESS';
var VALET_USAGE_BY_PERIODS_FAIL = exports.VALET_USAGE_BY_PERIODS_FAIL = '@Valet/VALET_USAGE_BY_PERIODS_FAIL';
var VALET_CUSTOMERS_REPORT_REQUEST = exports.VALET_CUSTOMERS_REPORT_REQUEST = '@Valet/VALET_CUSTOMERS_REPORT_REQUEST';
var VALET_CUSTOMERS_REPORT_SUCCESS = exports.VALET_CUSTOMERS_REPORT_SUCCESS = '@Valet/VALET_CUSTOMERS_REPORT_SUCCESS';
var VALET_CUSTOMERS_REPORT_FAIL = exports.VALET_CUSTOMERS_REPORT_FAIL = '@Valet/VALET_CUSTOMERS_REPORT_FAIL';
var LIST_PAYMENT_METHOD_FILTERS_REQUEST = exports.LIST_PAYMENT_METHOD_FILTERS_REQUEST = '@Package/LIST_PAYMENT_METHOD_FILTERS_REQUEST';
var LIST_PAYMENT_METHOD_FILTERS_SUCCESS = exports.LIST_PAYMENT_METHOD_FILTERS_SUCCESS = '@Package/LIST_PAYMENT_METHOD_FILTERS_SUCCESS';
var LIST_PAYMENT_METHOD_FILTERS_FAIL = exports.LIST_PAYMENT_METHOD_FILTERS_FAIL = '@Package/LIST_PAYMENT_METHOD_FILTERS_FAIL';
var LIST_SORT_BY_FILTERS_REQUEST = exports.LIST_SORT_BY_FILTERS_REQUEST = '@Package/LIST_SORT_BY_FILTERS_REQUEST';
var LIST_SORT_BY_FILTERS_SUCCESS = exports.LIST_SORT_BY_FILTERS_SUCCESS = '@Package/LIST_SORT_BY_FILTERS_SUCCESS';
var LIST_SORT_BY_FILTERS_FAIL = exports.LIST_SORT_BY_FILTERS_FAIL = '@Package/LIST_SORT_BY_FILTERS_FAIL';
var VALET_ABILITY_REQUEST = exports.VALET_ABILITY_REQUEST = '@Package/VALET_ABILITY_REQUEST';
var VALET_ABILITY_SUCCESS = exports.VALET_ABILITY_SUCCESS = '@Package/VALET_ABILITY_SUCCESS';
var VALET_ABILITY_FAIL = exports.VALET_ABILITY_FAIL = '@Package/VALET_ABILITY_FAIL';
var CURRENT_VALET_FEE_REQUEST = exports.CURRENT_VALET_FEE_REQUEST = '@Package/CURRENT_VALET_FEE_REQUEST';
var CURRENT_VALET_FEE_SUCCESS = exports.CURRENT_VALET_FEE_SUCCESS = '@Package/CURRENT_VALET_FEE_SUCCESS';
var CURRENT_VALET_FEE_FAIL = exports.CURRENT_VALET_FEE_FAIL = '@Package/CURRENT_VALET_FEE_FAIL';
var VALET_ALLOW_CHECKOUT_REQUEST = exports.VALET_ALLOW_CHECKOUT_REQUEST = '@Package/VALET_ALLOW_CHECKOUT_REQUEST';
var VALET_ALLOW_CHECKOUT_SUCCESS = exports.VALET_ALLOW_CHECKOUT_SUCCESS = '@Package/VALET_ALLOW_CHECKOUT_SUCCESS';
var VALET_ALLOW_CHECKOUT_FAIL = exports.VALET_ALLOW_CHECKOUT_FAIL = '@Package/VALET_ALLOW_CHECKOUT_FAIL';
var VALET_PAYMENT_SUMMARY_REQUEST = exports.VALET_PAYMENT_SUMMARY_REQUEST = '@Package/VALET_PAYMENT_SUMMARY_REQUEST';
var VALET_PAYMENT_SUMMARY_SUCCESS = exports.VALET_PAYMENT_SUMMARY_SUCCESS = '@Package/VALET_PAYMENT_SUMMARY_SUCCESS';
var VALET_PAYMENT_SUMMARY_FAIL = exports.VALET_PAYMENT_SUMMARY_FAIL = '@Package/VALET_PAYMENT_SUMMARY_FAIL';
var EXPORT_CUSTOMERS_REPORT_PDF_REQUEST = exports.EXPORT_CUSTOMERS_REPORT_PDF_REQUEST = '@Package/EXPORT_CUSTOMERS_REPORT_PDF_REQUEST';
var EXPORT_CUSTOMERS_REPORT_PDF_SUCCESS = exports.EXPORT_CUSTOMERS_REPORT_PDF_SUCCESS = '@Package/EXPORT_CUSTOMERS_REPORT_PDF_SUCCESS';
var EXPORT_CUSTOMERS_REPORT_PDF_FAIL = exports.EXPORT_CUSTOMERS_REPORT_PDF_FAIL = '@Package/EXPORT_CUSTOMERS_REPORT_PDF_FAIL';
var EXPORT_CUSTOMERS_REPORT_CSV_REQUEST = exports.EXPORT_CUSTOMERS_REPORT_CSV_REQUEST = '@Package/EXPORT_CUSTOMERS_REPORT_CSV_REQUEST';
var EXPORT_CUSTOMERS_REPORT_CSV_SUCCESS = exports.EXPORT_CUSTOMERS_REPORT_CSV_SUCCESS = '@Package/EXPORT_CUSTOMERS_REPORT_CSV_SUCCESS';
var EXPORT_CUSTOMERS_REPORT_CSV_FAIL = exports.EXPORT_CUSTOMERS_REPORT_CSV_FAIL = '@Package/EXPORT_CUSTOMERS_REPORT_CSV_FAIL';
var CHANGE_VALET_FEE_REQUEST = exports.CHANGE_VALET_FEE_REQUEST = '@Package/CHANGE_VALET_FEE_REQUEST';
var CHANGE_VALET_FEE_SUCCESS = exports.CHANGE_VALET_FEE_SUCCESS = '@Package/CHANGE_VALET_FEE_SUCCESS';
var CHANGE_VALET_FEE_FAIL = exports.CHANGE_VALET_FEE_FAIL = '@Package/CHANGE_VALET_FEE_FAIL';
var CURRENT_PRICE_TABLE_FEE_REQUEST = exports.CURRENT_PRICE_TABLE_FEE_REQUEST = '@Package/CURRENT_PRICE_TABLE_FEE_REQUEST';
var CURRENT_PRICE_TABLE_FEE_SUCCESS = exports.CURRENT_PRICE_TABLE_FEE_SUCCESS = '@Package/CURRENT_PRICE_TABLE_FEE_SUCCESS';
var CURRENT_PRICE_TABLE_FEE_FAIL = exports.CURRENT_PRICE_TABLE_FEE_FAIL = '@Package/CURRENT_PRICE_TABLE_FEE_FAIL';