"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestRemoveAdminFromCondo = exports.requestGetCondo = exports.requestEditCondoConfiguration = exports.requestEditCondo = void 0;
var _utils = require("../../../utils");
var _variables = require("../variables");
var requestEditCondo = exports.requestEditCondo = function requestEditCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_CONDO_REQUEST, payload, meta);
};
var requestEditCondoConfiguration = exports.requestEditCondoConfiguration = function requestEditCondoConfiguration(payload, meta) {
  return (0, _utils.buildAction)(_variables.EDIT_CONDO_CONFIGURATION_REQUEST, payload, meta);
};
var requestGetCondo = exports.requestGetCondo = function requestGetCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.GET_CONDO_REQUEST, payload, meta);
};
var requestRemoveAdminFromCondo = exports.requestRemoveAdminFromCondo = function requestRemoveAdminFromCondo(payload, meta) {
  return (0, _utils.buildAction)(_variables.REMOVE_ADMIN_FROM_CONDO_REQUEST, payload, meta);
};