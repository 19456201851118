"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REMOVE_ADMIN_FROM_CONDO_SUCCESS = exports.REMOVE_ADMIN_FROM_CONDO_REQUEST = exports.REMOVE_ADMIN_FROM_CONDO_FAIL = exports.GET_CONDO_SUCCESS = exports.GET_CONDO_REQUEST = exports.GET_CONDO_FAIL = exports.EDIT_CONDO_SUCCESS = exports.EDIT_CONDO_REQUEST = exports.EDIT_CONDO_FAIL = exports.EDIT_CONDO_CONFIGURATION_SUCCESS = exports.EDIT_CONDO_CONFIGURATION_REQUEST = exports.EDIT_CONDO_CONFIGURATION_FAIL = void 0;
var EDIT_CONDO_REQUEST = exports.EDIT_CONDO_REQUEST = '@Condominium/EDIT_CONDO_REQUEST';
var EDIT_CONDO_SUCCESS = exports.EDIT_CONDO_SUCCESS = '@Condominium/EDIT_CONDO_SUCCESS';
var EDIT_CONDO_FAIL = exports.EDIT_CONDO_FAIL = '@Condominium/EDIT_CONDO_FAIL';
var EDIT_CONDO_CONFIGURATION_REQUEST = exports.EDIT_CONDO_CONFIGURATION_REQUEST = '@Condominium/EDIT_CONDO_CONFIGURATION_REQUEST';
var EDIT_CONDO_CONFIGURATION_SUCCESS = exports.EDIT_CONDO_CONFIGURATION_SUCCESS = '@Condominium/EDIT_CONDO_CONFIGURATION_SUCCESS';
var EDIT_CONDO_CONFIGURATION_FAIL = exports.EDIT_CONDO_CONFIGURATION_FAIL = '@Condominium/EDIT_CONDO_CONFIGURATION_FAIL';
var GET_CONDO_REQUEST = exports.GET_CONDO_REQUEST = '@Condominium/GET_CONDO_REQUEST';
var GET_CONDO_SUCCESS = exports.GET_CONDO_SUCCESS = '@Condominium/GET_CONDO_SUCCESS';
var GET_CONDO_FAIL = exports.GET_CONDO_FAIL = '@Condominium/GET_CONDO_FAIL';
var REMOVE_ADMIN_FROM_CONDO_REQUEST = exports.REMOVE_ADMIN_FROM_CONDO_REQUEST = '@Condominium/REMOVE_ADMIN_FROM_CONDO_REQUEST';
var REMOVE_ADMIN_FROM_CONDO_SUCCESS = exports.REMOVE_ADMIN_FROM_CONDO_SUCCESS = '@Condominium/REMOVE_ADMIN_FROM_CONDO_SUCCESS';
var REMOVE_ADMIN_FROM_CONDO_FAIL = exports.REMOVE_ADMIN_FROM_CONDO_FAIL = '@Condominium/REMOVE_ADMIN_FROM_CONDO_FAIL';